import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/seo/Seo'

const whoAreWe = () => {
  return (
    <>
      <Layout title='Who Are We?' subtitle='find us behind the toy racks'>
        <SEO
          title='TOYREADS - Who Are We?'
          pathname='privacy-policy'
          desc='find us behind the toy racks'
        />
      </Layout>
    </>
  )
}

export default whoAreWe
